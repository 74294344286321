<script setup lang="ts">
import { setAddUserCollection } from '~/api/detail'
import { useMedia } from '~/store/media'
import type { HasSeriesMediaType } from '~/types'
import { validateMediaType } from '~/utils/validate'

definePageMeta({
  key: (route) => route.fullPath,
  validate: ({ params }) => validateMediaType((params as any).type)
})

const pageNum = ref(1)
const mediaStore = useMedia()
const videoId = useRouteParam<string>('id')
const videoSerialId = useRouteParam<string>('serial')
const mediaType = useRouteParam<HasSeriesMediaType>('type')
const videoUrl = ref('')

async function handleMediaDetail() {
  try {
    await mediaStore.handleMediaDetail(videoId.value)
    const videoSerialList = mediaStore.mediaDetail.ysVideoSeriesPOList || []
    videoUrl.value = videoSerialList.find((item) => item.videoSeriesId === videoSerialId.value)?.videoUrl || ''
  } catch (e) {}
}

async function handleGuessPrefer() {
  try {
    await mediaStore.handleGuessPrefer({ pageNum: pageNum.value, videoSiteId: videoId.value })
    pageNum.value++
  } catch (e) {}
}

async function reportVideoPlayback() {
  try {
    await setAddUserCollection({
      userFlag: 1,
      videoId: videoId.value
    })
  } catch (e) {}
}

watchEffect(() => {
  if (useUser().userInfo.id && isBoolean(useUser().isRealUser)) {
    handleMediaDetail()
  }
})

await handleMediaDetail()

onMounted(() => {
  handleGuessPrefer()
  reportVideoPlayback()
})

useSeoMeta({
  title: mediaStore.mediaDetail.videoName
})
</script>

<template>
  <section ma mb-10 h-full lg:flex>
    <VideoPlayer :url="videoUrl" class="mb-5 lg:mb-0 lg:w-40% lg:flex-1" />
    <div class="mt-1 flex-col justify-between lg:w-60% lg:flex lg:flex-1 lg:pl-4">
      <div class="mb-4 -mt-1">
        <VideoIntroduction :type="mediaType" :infos="mediaStore.mediaDetail" @refresh="handleMediaDetail" />
      </div>
    </div>
  </section>
  <VideoSeriesList has-radius :type="mediaType" :list="mediaStore.videoSeriesList" />
  <BannerPage :banner-type="{ type: 2, code: 74 }" my-3 xl:my-5 />
  <!-- <MediaGrid @header-click="handleGuessPrefer">
    <template #header> 猜你喜欢 </template>
    <MediaCard
      v-for="cards in mediaStore.guessPreferList"
      :key="cards.videoSiteId"
      :type="getVideoType(cards.videoSiteType)"
      :item="cards"
    />
  </MediaGrid> -->
</template>
